import { render, staticRenderFns } from "./StoryCarouselWidgetStory.vue?vue&type=template&id=71b2d18a&scoped=true"
import script from "./StoryCarouselWidgetStory.vue?vue&type=script&setup=true&lang=ts"
export * from "./StoryCarouselWidgetStory.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./StoryCarouselWidgetStory.vue?vue&type=style&index=0&id=71b2d18a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71b2d18a",
  null
  
)

export default component.exports