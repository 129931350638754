type HydrateSsrRoot<HydrationData> =
	/**
	 * @param {HTMLElement} root The HTMLELement to which the app should be mounted
	 * @param {HydrationData | undefined} data The JSON.parse'd hydration data from the SSR root (or undefined, if none)
	 */
	(root: HTMLElement, data: HydrationData | undefined) => void

/**
 * Hydrate the server-rendered HTML for a given `kind` of SSR'd app.
 *
 * @param {string} kind Corresponds to the `kind` prop passed to the `SsrRoot` component. Used to select any matching root elements in the DOM
 * @param {HydrateSsrRoot<HydrationData>} hydrate callback function receiving the root and hydration data. Mount the Vue app here.
 */
export function hydrateSsrRoots<HydrationData>(kind: string, hydrate: HydrateSsrRoot<HydrationData>) {
	const roots = document.querySelectorAll(`[data-ssr-kind="${kind}"]`);
	for (const rootEl of roots) {
		if (!(rootEl instanceof HTMLElement)) {
			continue;
		}

		const { hydrationData } = rootEl.dataset ?? {};
		const parsedData = hydrationData != null
			? JSON.parse(hydrationData)
			: undefined;

		hydrate(rootEl, parsedData);
	}
}
