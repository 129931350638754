import { render, staticRenderFns } from "./StoryCarouselWidget.vue?vue&type=template&id=185f34f4&scoped=true"
import script from "./StoryCarouselWidget.vue?vue&type=script&setup=true&lang=ts"
export * from "./StoryCarouselWidget.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./StoryCarouselWidget.vue?vue&type=style&index=0&id=185f34f4&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "185f34f4",
  null
  
)

export default component.exports