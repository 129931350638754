import App from "./components/App.vue";
import CarouselTrackingService from "JS/services/CarouselTrackingService";
import { defineComponent, h } from "vue";
import { DxpPageSsrRequest } from "./types/page";
import { provideCarouselTrackingService } from "./composables/product-carousel-tracking";

export default function createApp(requestData: DxpPageSsrRequest) {
	return defineComponent({
		setup() {
			provideCarouselTrackingService(new CarouselTrackingService());

			return () => h(App, {
				props: {
					requestData,
				}
			});
		}
	});
}