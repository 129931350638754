export enum DxpColor {
	DarkBlue = 0,
	LightBlue = 1,
	NeutralLight = 2, 
	DarkRed = 3,
	Ochre = 4,
	Emerald = 5,
	Teal = 6,
	LightYellow = 8,
}

export type DxpColorMap = { [K in DxpColor]: string };

export const dxpHexColorMap: DxpColorMap = {
	[DxpColor.DarkBlue]: '#01305F',
	[DxpColor.LightBlue]: '#E5F4FF',
	[DxpColor.NeutralLight]: '#F2F3F8',
	[DxpColor.DarkRed]: '#AA141A',
	[DxpColor.Ochre]: '#985800',
	[DxpColor.Emerald]: '#007729',
	[DxpColor.Teal]: '#016B74',
	[DxpColor.LightYellow]: '#FFDDA5',
};

export enum WidgetColorSetting {
	One,
	Two,
	Three,
	Accent,
}