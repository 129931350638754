/* Utility type which ensures only CSS custom property keys */
type CssVarObject<T extends Record<string, string | number>> = { [K in keyof T]: K extends `--${infer _}` ? T[K] : never };

/**
 * Utility which converts an object of '--css-var-name' -> value to a string appropriate for
 * binding to an element's inline style. We need this for SSR at the moment, because unfortunately,
 * Vue 2 strips a) css var object style bindings and b) the css vars applied by using v-bind in the
 * stylesheet during SSR
 */
export function cssVarsToStyleString<T extends Record<string, string | number>>(vars: CssVarObject<T>): string {
	return Object.entries(vars).reduce((result, [name, value]) => {
		if (value == null) {
			return result;
		}

		// need to remove newlines and tabs. they get written to the SSR HTML as literal "\n" and "\t"
		// and the browser doesn't _read them_ as newlines/tabs. that causes invalid styles without JS
		// and results in CLS with JS as it hydrates element style properties with the valid values
		return result + `${name}: ${value.toString().replace(/\n|\t/g, ' ')};`;
	}, '');
}