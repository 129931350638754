import { pipe, Thunk } from "./fp";

export interface Iterable<T> {
	[Symbol.iterator](): IterableIterator<T>
}

function normalize<T, Fn extends (iterable: IterableIterator<T>) => any>(fn: Fn): (iter: Iterable<T>) => ReturnType<Fn> {
	return iterable => {
		return fn(iterable[Symbol.iterator]());
	};
}

/**
 * Returns a generator which produces an infinite sequence of values, each calculated from the last.
 *
 * @param next Receives the previous value and produces the next value
 * @param initial the initial value in the sequence
 *
 * @example
 * const naturalNumbers = iterate(n => n + 1, 0);
 * naturalNumbers.next().value // 0
 * naturalNumbers.next().value // 1
 * naturalNumbers.next().value // 2
 * // ... and so on
 */
export function* iterate<T>(next: (prev: T) => T, initial: T): Generator<T, void, undefined> {
	let current = initial;
	while (true) {
		yield current;
		current = next(current);
	}
}

/**
 * Returns a generator which produces an infinite sequence of numbers, each calculated by adding `step` to the last.
 *
 * @param from the starting number in the sequence
 * @param step the amount to add to the last number. Defaults to 1. Use a negative number to produce a descending sequence.
 *
 * @example
 * const naturalNumbers = numbers(0);
 * naturalNumbers.next().value // 0
 * naturalNumbers.next().value // 1
 * naturalNumbers.next().value // 2
 * // ... and so on
 *
 * @example
 * const halfIncrements = numbers(0, 0.5);
 * halfIncrements.next().value // 0
 * halfIncrements.next().value // 0.5
 * halfIncrements.next().value // 1
 * halfIncrements.next().value // 1.5
 * // ... and so on
 */
export function numbers(from: number, step = 1): Generator<number, void, undefined> {
	return iterate(n => n + step, from);
}

/**
 * Given an iterable (possibly infinite), returns a finite iterator with `n` items. If the iterable
 * is an IterableIterator (not an array), the values are consumed. This function is curried.
 *
 * @param n the number of items to take from the iterable
 *
 * @example <caption>Take 5 numbers from the infinite sequence of natural numbers</caption>
 * const naturalNumbers = numbers(0);
 * const only5 = take(5)(naturalNumbers)
 * only5.next() // { done: false, value: 0 }
 * only5.next() // { done: false, value: 1 }
 * only5.next() // { done: false, value: 2 }
 * only5.next() // { done: false, value: 3 }
 * only5.next() // { done: false, value: 4 }
 * only5.next() // { done: true, value: undefined }
 *
 * // the first five items of naturalNumbers have been consumed:
 * naturalNumbers.next().value // 5
 *
 * @example <caption>Used with an array</caption>
 * const arr = [0, 1, 2, 3, 4, 5];
 * const only2 = take(2)(arr);
 * only2.next() // { done: false, value: 0 }
 * only2.next() // { done: false, value: 1 }
 * only2.next() // { done: true, value: undefined }
 *
 * // arr's values are not consumed:
 * arr // [0, 1, 2, 3, 4, 5]
 *
 * @example <caption>Partial application</caption>
 * const take2 = take(2);
 * [...take2([1, 2, 3])]; // [1, 2]
 * [...take2([0, 0, 0, 0, 0])]; // [0, 0]
 */
export function take(n: number): <T>(iterable: Iterable<T>) => Generator<T, void, undefined> {
	if (n < 0) {
		throw new Error('n must be a positive number');
	}

	return normalize(function* (iterator) {
		for (let count = n; count > 0; count--) {
			const { done, value } = iterator.next();
			if (done) {
				break;
			}
			yield value;
		}
	});
}

/**
 * Given an iterable (possibly infinite), returns an iterator with the first `n` items removed. If the iterable
 * is an IterableIterator (not an array), the values are consumed. This function is curried.
 *
 * @param n the number of items to remove from the front of the iterable
 *
 */
export function drop(n: number): <T>(iter: Iterable<T>) => Generator<T, void, undefined> {
	if (n < 0) {
		throw new Error('n must be a positive number');
	}

	return normalize(function* (iter) {
		for (let count = n; count > 0; count--) {
			if (iter.next().done) { // advance the iterator by one. if done, we don't need to do anything else
				return;
			}
		}

		yield* iter;
	});
}

/**
 * Returns a generator that produces a sequence of numbers from `from` to `to`, stepped by one.
 * Inclusive, i.e. range(1, 3) produces a sequence of 1, 2, 3. If `from` is greater than `to`,
 * then the sequence runs from `from` to `to` in descending order
 * @param from starting number
 * @param to end number (inclusive)
 */
export function* range(from: number, to: number): Generator<number, void, undefined> {
	const step = from > to ? -1 : 1;
	yield* take(Math.abs(from - to) + 1)(numbers(from, step));
}

/**
 * Analagous to Array.prototype.map
 */
export function map<T, U>(fn: (item: T) => U): (iterable: Iterable<T>) => IterableIterator<U> {
	return normalize(function* (iter) {
		while (true) {
			const { done, value } = iter.next();
			if (done) {
				break;
			}
			yield fn(value);
		}
	});
}

/**
 * Analagous to Array.prototype.filter
 */
export function filter<T>(pred: (item: T) => boolean): (iter: Iterable<T>) => IterableIterator<T> {
	return normalize(function* (iter) {
		while (true) {
			const { done, value } = iter.next();
			if (done) {
				break;
			}
			if (pred(value)) {
				yield value;
			}
		}
	});
}

const always = () => true;

/**
 * Returns a function which takes an iterable and returns its first item, or undefined if empty.
 */
export function first<T>(): (iter: Iterable<T>) => T | undefined
/**
 * Returns a function which takes an iterable and returns the first item which satisfies the predicate, or undefined if none match or it's empty.
 */
export function first<T>(pred: (item: T) => boolean): (iter: Iterable<T>) => T | undefined
export function first<T>(pred: (item: T) => boolean = always): (iter: Iterable<T>) => T | undefined {
	return iter => filter(pred)(iter).next().value;
}

/**
 * Returns a function which takes an iterable and returns its first item, or the result of `createDefaultValue` if empty.
 *
 * @param createDefaultValue a function with no arguments that returns a default value if the iterable is empty
 */
export function firstOrDefault<T>(createDefaultValue: Thunk<T>): (iter: Iterable<T>) => T
/**
 * Returns a function which takes an iterable and returns the first item which satisfies the predicate, or the result of `createDefaultValue` if none match or it's empty.
 *
 * @param createDefaultValue a function with no arguments that returns a default value
 */
export function firstOrDefault<T>(createDefaultValue: Thunk<T>, pred: (item: T) => boolean): (iter: Iterable<T>) => T
export function firstOrDefault<T>(createDefaultValue: Thunk<T>, pred: (item: T) => boolean = always): (iter: Iterable<T>) => T {
	return iter => first(pred)(iter) ?? createDefaultValue();
}

/**
 * Maps the items of an iterable into tuples of [index, item]
 *
 * @param iter
 */
export function enumerate<T>(iter: Iterable<T>): IterableIterator<[number, T]> {
	return zip<number, T>(numbers(0))(iter);
}

export function zipWith<T, U, V>(fn: (a: T, b: U) => V): (iterA: Iterable<T>) => (iterB: Iterable<U>) => IterableIterator<V> {
	return normalize(iterA => normalize(function* (iterB: IterableIterator<U>) {
		let a = iterA.next();
		let b = iterB.next();

		while (!a.done && !b.done) {
			yield fn(a.value, b.value);
			a = iterA.next();
			b = iterB.next();
		}
	}));
}

export function zip<T, U>(iterA: Iterable<T>): (iterB: Iterable<U>) => IterableIterator<[T, U]> {
	return pipe(
		iterA,
		zipWith((a, b) => [a, b]),
	);
}

/**
 * Analagous to Array.prototype.reduce
 */
export function reduce<T>(reducer: (acc: T, item: T) => T): (iter: Iterable<T>) => T | undefined
export function reduce<T, U>(reducer: (acc: U, item: T) => U, initial: U): (iter: Iterable<T>) => U
export function reduce(...args: [reducer: (acc: any, item: any) => any, initial?: any]): (iter: Iterable<any>) => any {
	return normalize(iter => {
		const [reducer, initial] = args;
		let result = args.length === 2 ? initial : iter.next().value;
		for (const item of iter) {
			result = reducer(result, item);
		}

		return result;
	});
}

/**
 * Analagous to Array.prototype.flatMap
 */
export function flatMap<T, U>(fn: (item: T) => Iterable<U>): (iter: Iterable<T>) => IterableIterator<U> {
	return iter => pipe(
		iter,
		map(fn),
		concat
	);
}

/**
 * Join many Iterables into a single iterator in order
 */
export function* concat<T>(iters: Iterable<Iterable<T>>): IterableIterator<T> {
	for (const iter of iters) {
		yield* iter;
	}
}

/**
 * Apply a function for side effects to each item. Returns a new iterable with the items of the original.
 */
export function tap<T>(fn: (item: T) => void): (iter: Iterable<T>) => IterableIterator<T> {
	return map(item => {
		fn(item);
		return item;
	});
}

export function nth(n: number): <T>(iter: Iterable<T>) => T | undefined {
	if (n < 0) {
		throw new Error('n must be a positive number');
	}

	return iter => {
		for (const item of iter) {
			if (n === 0) {
				return item;
			}
			n--;
		}
	};
}
