import { render, staticRenderFns } from "./PostSkeleton.vue?vue&type=template&id=3b7b7466&scoped=true"
var script = {}
import style0 from "./PostSkeleton.vue?vue&type=style&index=0&id=3b7b7466&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7b7466",
  null
  
)

export default component.exports