import CarouselTrackingService, { ICarouselTrackingService } from "JS/services/CarouselTrackingService";
import { CarouselType, ICarouselViewData, IProductCarouselGtmProduct } from "JS/types/Carousel";
import { computed, inject, InjectionKey, provide, WatchSource } from "vue";
import { getPriceMode, IProductPriceViewModel } from "JS/types/Product";

const carouselTrackingServiceKey: InjectionKey<ICarouselTrackingService> = Symbol();

export function provideCarouselTrackingService(service: ICarouselTrackingService) {
	provide(carouselTrackingServiceKey, service);
}

export function useProductCarouselTracking(opts: WatchSource<{
	name: string;
	type: CarouselType,
	products: IProductCarouselGtmProduct[];
}>) {

	const options = computed(() => {
		return typeof opts === 'function'
			? opts()
			: opts.value;
	});

	const trackingService = inject(carouselTrackingServiceKey, () => new CarouselTrackingService(), true);

	const products = computed(() => options.value.products);

	let carouselView: ICarouselViewData | null = null;
	function trackView() {
		if (carouselView) {
			return;
		}

		carouselView = {
			carouselName: options.value.name,
			type: options.value.type,
			url: window.location.pathname + window.location.search,
		};

		trackingService.trackViews([carouselView], [{
			itemListID: `carousel_${carouselView.type.toString()}`,
			itemListName: carouselView.carouselName,
			carouselProducts: products.value,
		}]);
	}

	type CarouselTrackingProduct = {
		blainNumber: string;
		price: IProductPriceViewModel;
		brand: string;
		name: string;
	};

	function trackClick(product: CarouselTrackingProduct, index: number) {
		trackView(); // ensures carouselView is defined

		trackingService.trackClick({
			blainNumber: product.blainNumber,
			currentPrice: product.price.currentPrice,
			carousel: carouselView,
			itemIndex: index,
		}, {
			itemListID: `carousel_${carouselView.type.toString()}`,
			itemListName: carouselView.carouselName,
			currentPrice: product.price.currentPrice,
			belowMap: product.price.isBelowMAP,
			priceMode: getPriceMode(product.price),
			blainNumber: product.blainNumber,
			vendorBrand: product.brand,
			productName: product.name,
			index: index
		});
	}

	return {
		trackView,
		trackClick,
	};
}