var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"brand-logos-widget-stacked",style:(_setup.cssVarsToStyleString({
		'--blw-columns--mobile': _setup.columns.mobile,
		'--blw-columns--mobile-large': _setup.columns.mobileLarge,
		'--blw-columns--tablet': _setup.columns.tablet,
		'--blw-columns--desktop': _setup.columns.desktop,
	}))},[_c('ul',{staticClass:"brand-logos-widget-stacked__grid",attrs:{"role":"list"}},_vm._l((_vm.items),function(item,i){return _c('li',{key:item.link,style:(_setup.cssVarsToStyleString({
				'--blw-shift-item-by-spaces--desktop': _setup.getSpacesToShiftItem(i, _setup.columns.desktop),
				'--blw-shift-item-by-spaces--tablet': _setup.getSpacesToShiftItem(i, _setup.columns.tablet),
				'--blw-shift-item-by-spaces--mobile': _setup.getSpacesToShiftItem(i, _setup.columns.mobile),
				'--blw-shift-item-by-spaces--mobile-large': _setup.getSpacesToShiftItem(i, _setup.columns.mobileLarge),
			}))},[_vm._t("default",null,{"item":item})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }