import createApp from "./app";
import Vue from 'vue';
import { DxpPageSsrRequest } from "./types/page";
import { hydrateSsrRoots } from "JS/ssr/client-utilities";

hydrateSsrRoots<DxpPageSsrRequest>('dxp', (el, data) => {
	new Vue({
		extends: createApp(data),
		el,
	});
});