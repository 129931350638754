import { BaseWidget, WidgetResource, WidgetType } from "./base";

export interface TileGridWidget extends BaseWidget {
	type: WidgetType.TileGridWidget;
	heading: string;
	showTitle: boolean;
	sections: TileGridWidgetSection[];
}

export enum TileGridWidgetSectionType {
	Primary,
	Tall,
	Short,
	DoublePrimary,
}

export interface TileGridWidgetSection {
	type: TileGridWidgetSectionType;
	tiles: TileGridWidgetTile[];
}

export enum TileGridWidgetTileType {
	Invalid,
	Image,
}

export enum TileGridWidgetTileShape {
	Primary,
	Tall,
	Short,
}

export interface BaseTileGridWidgetTile {
	shape: TileGridWidgetTileShape;
}

export interface TileGridWidgetInvalidTile extends BaseTileGridWidgetTile {
	type: TileGridWidgetTileType.Invalid;
	json: string;
}

export interface TileGridWidgetImageTile extends BaseTileGridWidgetTile {
	type: TileGridWidgetTileType.Image;
	imageUrl: string;
	imageUrlWebP: string;
	linkUrl: string;
	altText: string;
	shape: TileGridWidgetTileShape;
}

export type TileGridWidgetTile = TileGridWidgetInvalidTile | TileGridWidgetImageTile;

export type TileGridTileDimensions = {
	width: number;
	height: number;
}

export interface TileGridWidgetData {
	tilesPerRowBySectionType: Record<TileGridWidgetSectionType, number>;
	tileDimensionsByTileShape: Record<TileGridWidgetTileShape, TileGridTileDimensions>;
}

export type TileGridWidgetResource = WidgetResource<TileGridWidget, TileGridWidgetData>;