import { render, staticRenderFns } from "./PetHeader.vue?vue&type=template&id=4625c83c&scoped=true"
import script from "./PetHeader.vue?vue&type=script&setup=true&lang=ts"
export * from "./PetHeader.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./PetHeader.vue?vue&type=style&index=0&id=4625c83c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4625c83c",
  null
  
)

export default component.exports