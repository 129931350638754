import { BaseWidget, WidgetResource, WidgetType } from "./base";

export interface PrebuiltHeaderWidget extends BaseWidget {
	type: WidgetType.PrebuiltHeaderWidget;
	variant: PrebuiltHeaderVariant;
}

export enum PrebuiltHeaderVariant {
	HorseCareAndTack,
	PoultrySupplies,
	Kitchen,
	Tools,
	Pet,
	Dog,
	Cat,
}

export interface PrebuiltCategoryHeaderData {
	subcategories: PrebuiltCategoryHeaderDataSubCategory[];
}

export interface PrebuiltCategoryHeaderDataSubCategory {
	name: string;
	url: string;
}

export type PrebuiltHeaderWidgetResource = WidgetResource<PrebuiltHeaderWidget, PrebuiltCategoryHeaderData>
