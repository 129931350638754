import { IProductPriceViewModel } from "JS/types/Product";

export enum StoryAndItemCarouselLayout {
	A,
	B,
	C,
}

export interface DxpProductCarouselProduct {
	name: string;
	brand: string;
	blainNumber: string;
	price: IProductPriceViewModel;
	avgRating: number | null;
	numReviews: number | null;
	url: string;
	imageUrl: string;
	imageUrlWebP: string;
	showInStoreOnlyMessage: boolean;
	isComingSoon: boolean;
}